import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class LoadingComponent {
  @Input() bar = false;
  @Input() button = false;
  @Input() center = false;
  @Input() innerClass = '';
}
